import dayjs from 'dayjs'
// import store from '@/store'
// import { nextTick } from 'vue'
import { useRoute,useRouter } from 'vue-router';



export function getCookie(name) {
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  const result = document.cookie.match(reg)
  if (result) {
    return unescape(result[2])
  } else {
    return null
  }
}

// 导出文件
export async function toExportFile(url, params) {
  params = Object.keys(params).reduce((pre, next, index, arr) => {
    if (index === arr.length - 1) {
      return `${pre}${next}=${params[next] || ''}`
    } else {
      return `${pre}${next}=${params[next] || ''}&`
    }
  }, '?')
  window.open(`${window.location.origin}${url}${params}`)
}

// 格式化数字展示
export function formatNumber(number) {
  // 不是数字直接返回
  if (isNaN(parseInt(number))) {
    return number
  }
  if (number >= Math.pow(10, 8) || number < -Math.pow(10, 7)) {
    return (number / Math.pow(10, 8)).toFixed(2) + '亿'
  } else if (number > Math.pow(10, 4) || number < -Math.pow(10, 3)) {
    return (number / Math.pow(10, 4)).toFixed(2) + 'w'
  } else {
    return number
  }
}

// 百分比数字展示
export function percentageNumber(number) {
  // 不是数字直接返回
  if (isNaN(parseInt(number))) {
    return number
  }
  return (number * 100).toFixed(2)
}
// 判断数字正负
export function checkNumber(number) {
  // 不是数字直接返回
  if (isNaN(parseInt(number))) {
    return number
  }
  if (number >= 0) {
    return true
  } else {
    return false
  }
}
// 整理菜单数据排序
export function menuSort(list) {
  const menuList = Object.keys(list)
    .map((key) => {
      return list[key]
    })
    .sort((prev, next) => {
      return prev.order - next.order
    })

  return menuList
}
// 时间戳转标准时间展示
export function formatTime(time) {
  const date = new Date(time)
  const y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? '0' + m : m
  let d = date.getDate()
  d = d < 10 ? '0' + d : d
  let h = date.getHours()
  h = h < 10 ? '0' + h : h
  let minute = date.getMinutes()
  minute = minute < 10 ? '0' + minute : minute
  let second = date.getSeconds()
  second = second < 10 ? '0' + second : second
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
}
export const getDateStr = (dd) => {
  var y = dd.getFullYear()
  var m = dd.getMonth() + 1 // 获取当前月份的日期
  m = parseInt(m, 10)
  if (m < 10) {
    m = '0' + m
  }
  var d = dd.getDate()
  d = parseInt(d, 10)
  if (d < 10) {
    d = '0' + d
  }
  return y + '-' + m + '-' + d
}
export function formatTime2(timeArr) {
  return [
    dayjs(timeArr[0]).format('YYYY-MM-DD') + ' 00:00:00',
    dayjs(timeArr[1]).format('YYYY-MM-DD') + ' 23:59:59',
  ]
}

// 返回目标时间戳
export function times(num) {
  const nowTmie = new Date(new Date().toLocaleDateString())
  nowTmie.setTime(nowTmie.getTime() - 3600 * 1000 * 24 * num)
  const targetTime = nowTmie.getTime()
  return targetTime
}

// 进入某一个dom的全屏
export function requestFullScreen(element) {
  var requestMethod =
    element.requestFullScreen ||
    element.webkitRequestFullScreen ||
    element.mozRequestFullScreen ||
    element.msRequestFullScreen
  requestMethod && requestMethod.call(element)
}
// 退出全屏页面
export function exitFullscreen() {
  var exitMethod =
    document.exitFullscreen ||
    document.msExitFullscreen ||
    document.mozCancelFullScreen ||
    document.webkitExitFullscreen
  exitMethod && exitMethod.call(document)
}
export function checkFull() {
  var isFull =
    document.isFullScreen ||
    document.mozIsFullScreen ||
    document.webkitIsFullScreen
  if (isFull === undefined) {
    isFull = false
  }
  return isFull
}

// 将workbook装化成blob对象
export function workbook2blob(XLSX, workbook) {
  // 生成excel的配置项
  var wopts = {
    // 要生成的文件类型
    bookType: 'xlsx',
    // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    bookSST: false,
    // 二进制类型
    type: 'binary',
  }
  var wbout = XLSX.write(workbook, wopts)
  var blob = new Blob([s2ab(wbout)], {
    type: 'application/octet-stream',
  })
  return blob
}

// 将字符串转ArrayBuffer
export function s2ab(s) {
  var buf = new ArrayBuffer(s.length)
  var view = new Uint8Array(buf)
  for (var i = 0; i != s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff
  }
  return buf
}

// 将blob对象创建bloburl，然后用a标签实现弹出下载框
export function openDownloadDialog(blob, fileName) {
  if (typeof blob == 'object' && blob instanceof Blob) {
    blob = URL.createObjectURL(blob) // 创建blob地址
  }
  var aLink = document.createElement('a')
  aLink.href = blob
  // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，有时候 file:///模式下不会生效
  aLink.download = fileName || ''
  var event
  if (window.MouseEvent) event = new MouseEvent('click')
  //   移动端
  else {
    event = document.createEvent('MouseEvents')
    event.initMouseEvent(
      'click',
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null,
    )
  }
  aLink.dispatchEvent(event)
  URL.revokeObjectURL(blob)
}

export function readWorkbookFromLocalFile(XLSX, file) {
  return new Promise((resolve) => {
    var reader = new FileReader()
    reader.onload = function (e) {
      var data = e.target.result
      // 读取二进制的excel
      var workbook = XLSX.read(data, { type: 'binary' })
      resolve(workbook)
    }
    reader.readAsBinaryString(file.originFileObj)
  })
}
// 下载文件
// 为了文件能下载成功，需要在接口中添加  responseType: 'blob',
export function downloadFile(name, data) {
  // 接口中添加  responseType: 'blob',
  const url = window.URL.createObjectURL(new Blob([data]))
  let link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(link.href) // 释放URL 对象
  document.body.removeChild(link)
  link = null
}

export const getOneDayStartTime = (time) => {
  return time && time.length > 0
    ? dayjs(time[0].format('YYYY-MM-DD') + ' 00:00:00').valueOf()
    : ''
}
export const getOneDayEndTime = (time) => {
  return time && time.length > 0
    ? dayjs(time[1].format('YYYY-MM-DD') + ' 23:59:59').valueOf()
    : ''
}
// 将数组对象中的时间把t截取
export function splitStr(list, attr) {
	for (let i in list) {
		if (list[i][attr]) {
			list[i][attr] = list[i][attr].split("T")[0];
		}
	}
	return list;
}
// 横向滚动，使用滚动条显示进度条比例
export const changeXscroll = (ref,domId)=>{
  if(ref && domId){
    let refWidth = ref.value.scrollWidth 
    let refLeft = ref.value.scrollLeft
    let domIdWidth = document.getElementById(`${domId}`).offsetWidth
    return (refLeft+domIdWidth)/refWidth *100
  }else{
    console.log('请传入对应的ref的dom元素和滚动框id')
  }
}
// 点击跳转纵向滚动到对应id位置
export const jumpidName = (idName)=>{
  if(!idName){
      return
  }
  let offset = 200;
  let elem = document.getElementById(idName)
  const targetRect = elem.getBoundingClientRect(); 
  // 垂直滚动到目标元素的位置
  window.scrollTo({
      top: targetRect.top-offset,
      behavior: "smooth"
  });
}

const userAgent = navigator.userAgent
export const UA = {
  isWechat: !!userAgent.match(/MicroMessenger/gi), // 是否微信内
  isAlipay: !!userAgent.match(/aliapp/gi), // 支付宝
  isMobile: !!userAgent.match(/mobile/gi), // 是否移动端
  isApple: !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // 是否ios / mac 系统
  isAndroid: userAgent.indexOf("Android") > -1 || userAgent.indexOf("Linux") > -1, // 是否android
  isPc:/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent) //判断是否为移动端还是pc端，false是pc端，true是移动端
}
function findRoutesInRouter(router, condition) {
  const result = [];
  function traverseRoutes(routes) {
    for (const route of routes) {
      if (condition(route)) {
        result.push(route);
      }
      if (route.children && route.children.length > 0) {
        traverseRoutes(route.children);
      }
    }
  }
  traverseRoutes(router);
  return result;
}
function findParentData(arr, childPid) {
  for (let item of arr) {
    if (item.id === childPid) {
      return item; // 直接找到了对应的父级数据，返回它
    } else if (item.children && item.children.length > 0) {
      // 在子节点中递归查找
      const foundParent = findParentData(item.children, childPid);
      if (foundParent) {
        return foundParent;
      }
    }
  }
  return null; // 没有找到对应的父级数据，返回 null
}

// 示例数据（假设这是一个树形结构的数据，其中包含 id 和 children 属性）
const data = [
  { id: 1, name: '父级 1', children: [
      { id: 2, name: '子级 1-1', pid: 1 },
      { id: 3, name: '子级 1-2', pid: 1, children: [
          { id: 4, name: '孙级 1-2-1', pid: 3 }
        ]
      }
    ]
  },
  { id: 5, name: '父级 2', children: [
      { id: 6, name: '子级 2-1', pid: 5 }
    ]
  }
];


// 调用函数查找父级数据
const returnPathObj = (routerPath,windowUrl)=>{
  let obj = {}
  const condition = (route) => route.path === windowUrl;
  obj = findRoutesInRouter(routerPath, condition);
  return obj
} 

export const getUrl = (routerPath,str)=>{
  const path = window.location.pathname;
  if(!routerPath){
    return 
  }
  let arr = returnPathObj(routerPath,path),objPath
  console.log(arr)
  if(arr.length == 0)  return
  if(!arr[0].pId){
    console.log(2)
    objPath = arr[0].path
  }else{
    console.log(3)
    objPath =  findParentData(routerPath, arr[0].pId)
    objPath = objPath.path
  }
  return objPath
}
