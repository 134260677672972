<template>
    <div class="mobileFooter">
      <div class="box1">
        <div class="box1l">
          <img src="@/assets/icon/icon.png" alt="">
          <p>{{ jsonObj.footer.lt1 }}</p>
        </div>
        <div class="box1r">
          <img src="@/assets/icon/facebookyellow.png" alt="" @click.stop="jumpUrl('https://web.facebook.com/eiceducationvn')">
          <img src="@/assets/icon/Twitteryellow.png" alt="" @click.stop="jumpUrl('https://zalo.me/0906225916')">
        </div>
      </div>
      <div class="box2">
        <div class="box2-0">
          {{ jsonObj.footer.cont1 }}
        </div>
        <div class="box2-1">
          <p @click.stop="jumpRouterchild('story','user')">{{ jsonObj.footer.cont2_1 }}</p>
          <p @click="jumpRouterchild('cultivate','serve')">{{ jsonObj.footer.cont2_2 }}</p>
          <p @click.stop="jumpRouterchild('schoolmajor','school')">{{ jsonObj.footer.cont2_3 }}</p>
        </div>
        <div class="box2-1">
          <p @click.stop="jumpRouterchild('specialty','school')">{{ jsonObj.footer.cont2_4 }}</p>
          <p @click="jumpRouterchild('scholarship','scholarship')">{{ jsonObj.footer.cont2_5 }}</p>
          <p>
            <!-- {{ jsonObj.footer.cont2_6 }} -->
          </p>
        </div>
      </div>
      <div class="box3">
        <div class="box3-1">{{ jsonObj.footer.r1 }}</div>
        <el-form ref="formRef" :model="jsonObj.footerfrom" :rules="jsonObj.rules">
          <div class="fromdiv">
            <el-form-item prop="name">
              <el-input
                v-model="jsonObj.footerfrom.name"
                style="height: 9.1rem;;background: rgba(255,255,255,0.05);"
                :placeholder="jsonObj.footer.nameplaceholder"
              />
            </el-form-item>
            <div class="geduan"></div>
            <el-form-item prop="age">
              <el-input
              v-model="jsonObj.footerfrom.age"
              style="height: 9.1rem;;background: rgba(255,255,255,0.05);"
              :placeholder="jsonObj.footer.birthdayplaceholder"
            />
            </el-form-item>
          </div>
        <div class="fromdiv">
          <el-form-item prop="mobile">
            <el-input
            v-model="jsonObj.footerfrom.mobile"
            style="height: 9.1rem;;background: rgba(255,255,255,0.05);"
            :placeholder="jsonObj.footer.phoneplaceholder"
          />
          </el-form-item>
          <div class="geduan"></div>
          <el-form-item prop="email">
            <el-input
            v-model="jsonObj.footerfrom.email"
            style="height: 9.1rem;background: rgba(255,255,255,0.05);"
            :placeholder="jsonObj.footer.Emailplaceholder"
          />
          </el-form-item>
        </div>
        <div class="fromdiv learnandsend">
          <el-form-item prop="academicDegree" style="flex: 1;">
            <el-select
            v-model="jsonObj.footerfrom.academicDegree"
            :placeholder="jsonObj.footer.learnplaceholder"
            style="width: 100%;background: rgba(255,255,255,0.05);"
            size="large"
            clearable 
          >
            <el-option :label="jsonObj.footer.learnOption1" :value="jsonObj.footer.learnOption1"></el-option>
            <el-option :label="jsonObj.footer.learnOption2" :value="jsonObj.footer.learnOption2"></el-option>
            <el-option :label="jsonObj.footer.learnOption3" :value="jsonObj.footer.learnOption3"></el-option>
            <el-option :label="jsonObj.footer.learnOption4" :value="jsonObj.footer.learnOption4"></el-option>
          </el-select>
          </el-form-item>
          <div class="geduan"></div>
          <el-form-item>
            <!-- <el-button class="click"  :class="state.sendOK ? 'noClick' : ''" @click="send">{{ jsonObj.footer.send }}</el-button> -->
            <el-button class="click" @click="send">{{ jsonObj.footer.send }}</el-button>
          </el-form-item>
        </div>
      </el-form>
      </div>
      <div class="box4">
        <div>{{ jsonObj.newsFooter.title1 }}</div>
        <div class="box4-1">
          <img src="@/assets/icon/positionfff.png" alt="">
          <span>{{ jsonObj.footer.lt2_1 }}</span>
        </div>
        <div class="box4-1">
          <img src="@/assets/icon/ponefff.png" alt="">
          <span>{{ jsonObj.footer.lt2_2 }}</span>
        </div>
        <div class="box4-1" style="padding-bottom: 2.8rem;">
          <img src="@/assets/icon/emilsfff.png" alt="">
          <span>{{ jsonObj.footer.lt2_3 }}</span>
        </div>
        <div class="xian"></div>
        <div class="tips">{{jsonObj.footer.tips }}</div>
      </div>
      <a href="#mobileHeader">
        <img class="jumpTop" src="@/assets/icon/jumpTop.png" alt="" >
      </a>
    </div>
  </template>
  <script setup name="mobileFooter">
  import {  computed, ref,onMounted,  nextTick, watch, reactive } from 'vue';
 import { useRouter } from 'vue-router';
 import {jsonObj,imgUrl,submitForm} from '@/utlis/constJSON.js'
 import { useStore } from "vuex";
 const store = useStore()
  const path = computed(() => store.state.path);
 const router = useRouter()
 const state = reactive({
  sendOK:false,
 })
 const jumpUrl = (url)=>{
    window.open(url, '_blank');
  }
 const jumpRouterchild = (str,fatStr,query={})=>{
  jsonObj.headerNav = fatStr
  let str1 = `/${str}`
  router.push({
      path: str1,
      query:{},
    })
    path.value.forEach(v=>{
      v.isclick = false
      if(v?.children){
        v.children.forEach(m=>{
          m.isclick = false
          if(m.path == str1){
            m.isclick = !m.isclick
            v.isclick = true
          }
        })
      }else{
        if(v.path == str1){
          v.isclick = true
        }
      }
    })
    store.dispatch('updatePath',path.value)
}
const formRef = ref()
const send = ()=>{
    formRef.value?.validate(async (valid) => {
        // if(state.sendOK){
        //     return
        // }
        if (!valid) return false;
        await submitForm()
        // 调用发送接口
        // state.sendOK = true
    })
  }
  </script>
  <style lang="scss" scoped>
    .mobileFooter{
      margin: 0 auto;
      background: #004163;
      color: #fff;
      position: relative;
      .box1,.box2,.box3,.box4{
        border-bottom: 0.105rem solid #D8D8D8;
        padding: 5.25rem;
      }
      .box1{
        padding: 8.75rem 2.8rem 2.8rem;
        display: flex;
        justify-content: space-between;
        .box1l{
          img{
            width: 36.75rem;
            margin-bottom: 0.875rem;
          }
        }
        .box1r{
          img{
            width: 9.1rem;
            margin-left: 3.5rem;
          }
        }
      }
      .box2{
        .box2-0{
          font-size: 4.2rem;
        }
        .box2-1{
          margin-top: 3.8rem;
          display: flex;
          justify-content: space-between;
          p{
            width: 27.125rem;
            color: #D8D8D8;
            font-size: 3rem;
            text-align: left;
          }
        }
      }
      .box3{
        .box3-1{
          font-weight: 500;
          font-size: 4.2rem;
          margin-bottom: 3.5rem;
        }
        .fromdiv{
          display: flex;
          justify-content: space-between;
        }
        .geduan{
          width: 3.5rem;
        }
        .learnandsend{
          display: flex;
          justify-content: space-between;
          .click{
            width: 21rem;
            height: 9.45rem;
            background: #F5AA3B;
            border: 1px solid #AAAAAA;
          }
        }
      }
      .box4{
          font-size: 3.15rem;
          .box4-1{
            padding-top: 3.15rem;
            display: flex;
            justify-content: left;
            font-size: 3.15rem;
            img{
              width: 5.6rem;
              padding-right: 1.75rem;
            }
            span{
              color: #FFFFFF;
            }
          }
          .xian{
            width: 80%;
            border-top: 0.105rem solid #F5AA3B;
          }
        .tips{
            padding-top: 9.8rem;
            width: auto;
            font-weight: 300;
            font-size: 2.45rem !important;
            color: #FFFFFF;
          }
      }
      .jumpTop{
        width: 10.85rem;
        position: absolute;
        right: 3.5rem;
        bottom: 9.975rem;
        z-index: 1;
      }
      
    }
  </style>