import { createRouter, createWebHistory ,createWebHashHistory} from "vue-router";
import { path,newspath } from '@/router/path'
import { clearform,routerPath } from '@/utlis/constJSON'
import { reactive,ref } from 'vue';

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>import("@/views/home.vue"),
    // component: () =>import( "@/views/NewsDetails/Newstwo.vue"),
  },
  ...path,
  ...newspath,
];
const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  //赋值标题
  document.title = to.meta.title || '越南来华网站';
 
  // 在这里处理路由变化前的逻辑
  routerPath.value = to.path
  //每一次跳转路由都要清理掉数据
  clearform() 
  // 解决跳转保留上一个页面停留的位置问题
    window.scrollTo({
      top: 0,
    })
  next();
});

export const goRouter = (str,query={})=>{
  // let routers = router.resolve({path: `/${str}`,query: query})
  // window.open(routers.href, '_blank');
  router.push({
    path: `/${str}`,
    query,
  })
}

export default router;
