//用于移动端导航使用
export const path = [
    {
      path: "/home",
      name: "homes",
      chinese:"首页",
      label:'TRANG CHỦ',
      isclick:false,
      meta: {
        title: 'Du học Trung Quốc'
      },
      component: () =>import( "@/views/home.vue"),
    },
    {
      chinese:'找学校和专业',
      path: "/school",
      name: "school",
      isclick:false,
      id:1,
      label:'TÌM TRƯỜNG, NGÀNH HỌC',
      meta: {
        title: 'Du học và Xin học bổng'
      },
      children:[
        {
          chinese:'找专业',
          path: "/specialty",
          name: "specialty",
          isclick:false,
          pId:1,
          label:'TÌM NGÀNH',
          meta: {
            title: 'Du học và Xin học bổng'
          },
          component: () =>import( "@/views/specialty.vue"),
        },
        {
          chinese:'找学校',
          path: "/schoolmajor",
          name: "schoolmajor",
          isclick:false,
          pId:1,
          label:'TÌM TRƯỜNG',
          meta: {
            title: 'Du học và Xin học bổng'
          },
          component: () =>import( "@/views/schoolmajor.vue"),
        },
      ]
    },
    {
      path: "/serve",
      chinese:'培训和出国留学',
      name: "serve",
      isclick:false,
      id:2,
      label:'DỊCH VỤ',
      meta: {
          title: 'Tư vấn du học Trung Quốc'
        },
      children:[
        {
          path: "/cultivate",
          chinese:'培训',
          name: "cultivate",
          isclick:false,
          pId:2,
          label:'HỌC TIẾNG TRUNG',
          meta: {
              title: 'Tư vấn du học Trung Quốc'
            },
          component: () =>import( "@/views/cultivate.vue"),
        },
        {
          path: "/studyAbroadLong",
          chinese:'长期出国留学',
          name: "studyAbroadLong",
          isclick:false,
          pId:2,
          label:'DU HỌC DÀI HẠN',
          meta: {
              title: 'Tư vấn du học Trung Quốc'
            },
          component: () =>import( "@/views/studyAbroadLong.vue"),
        },
        {
          path: "/studyAbroadShort",
          chinese:'短期出国留学',
          name: "studyAbroadShort",
          isclick:false,
          pId:2,
          label:'DU HỌC NGẮN HẠN',
          meta: {
              title: 'Tư vấn du học Trung Quốc'
            },
          component: () =>import( "@/views/studyAbroadShort.vue"),
        },
      ]
    },
    { 
        path: "/scholarship",
        label:'HỌC BỔNG',
        name: "scholarship",
        isclick:false,
        id:3,
        label:'HỌC BỔNG',
        chinese:'奖学金',
        meta: {
            title: 'Xin học bổng du học Trung Quốc'
          },
        component: () =>import( "@/views/scholarship.vue"),
    },
    {
      path: "/user",
      chinese:'故事',
      label:'VỀ CHÚNG TÔI',
      isclick:false,
      id:4,
      name: "user",
      meta: {
          title: 'EIC Education Việt Nam'
        },
      children:[
        {
          path: "/partner",
          chinese:'伙伴',
          label:'ĐỐI TÁC CỦA CHÚNG TÔI',
          isclick:false,
          pId:4,
          name: "partner",
          meta: {
              title: 'EIC Education Việt Nam'
            },
          component: () =>import( "@/views/partner.vue"),
        },
        {
          path: "/story",
          chinese:'故事',
          label:'CÂU CHUYỆN',
          isclick:false,
          pId:4,
          name: "story",
          meta: {
              title: 'EIC Education Việt Nam'
            },
          component: () =>import( "@/views/story.vue"),
        },
        {
          path: "/contact",
          chinese:'联系',
          label:'LIÊN HỆ',
          isclick:false,
          pId:4,
          name: "contact",
          meta: {
              title: 'EIC Education Việt Nam'
            },
          component: () =>import( "@/views/contact.vue"),
        },
      ]
    },
  ]
  // 其他非导航页面的页路由
export const newspath = [
  {
    path: "/SchoolDetails/specialtyDetails",
    chinese:'专业详情',
    name: "specialtyDetails",
    label:'Du học và Xin học bổng',
    meta: {
        title: 'Du học và Xin học bổng'
      },
    component: () =>import( "@/views/SchoolDetails/specialtyDetails.vue"),
  },
  {
    path: "/SchoolDetails/schoolMajorDetails",
    chinese:'学校详情',
    name: "schoolMajorDetails",
    meta: {
        title: 'Du học và Xin học bổng'
      },
    component: () =>import( "@/views/SchoolDetails/schoolMajorDetails.vue"),
  },
  {
    path: "/NewsDetails/Newsone",
    chinese:'新闻详情1',
    name: "Newsone",
    meta: {
        title: 'Tư vấn du học Trung Quốc'
      },
    component: () =>import( "@/views/NewsDetails/newsone.vue"),
  },
  {
    path: "/NewsDetails/Newstwo",
    chinese:'新闻详情2',
    name: "Newstwo",
    meta: {
        title: 'Tư vấn du học Trung Quốc'
      },
    component: () =>import( "@/views/NewsDetails/newstwo.vue"),
  },
  {
    path: "/NewsDetails/Newsthree",
    chinese:'新闻详情3',
    name: "Newsthree",
    meta: {
        title: 'Tư vấn du học Trung Quốc'
      },
    component: () =>import( "@/views/NewsDetails/newsthree.vue"),
  },
  {
    path: "/NewsDetails/Newsfore",
    chinese:'新闻详情4',
    name: "Newsfore",
    meta: {
        title: 'Tư vấn du học Trung Quốc'
      },
    component: () =>import( "@/views/NewsDetails/newsfore.vue"),
  },
  {
    path: "/NewsDetails/Newsfive",
    chinese:'新闻详情5',
    name: "Newsfive",
    meta: {
        title: 'Tư vấn du học Trung Quốc'
      },
    component: () =>import( "@/views/NewsDetails/newsfive.vue"),
  },
]