import axios from 'axios'
const requestInstance = axios.create({
  // baseURL,
  timeout: 1000 * 60,
  withCredentials: true, // 跨域请求时是否需要使用凭证
  headers: {
    //  X-Requested-With 请求服务端接口，服务端用来判断是不是ajax请求的标识。 服务端接入了拦截sdk
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json; charset=utf-8',
  },
})

requestInstance.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  },
)

// 添加响应拦截器
requestInstance.interceptors.response.use(
  function (response) {
    const data = response.data
    // 对响应数据做点什么
    return data
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error)
  },
)

export default requestInstance
