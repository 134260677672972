<template>
    <div class="footer">
      <div class="footerbox">
        <div class="left">
        <img src="@/assets/icon/icon.png" alt="">
        <div class="lt1">
          {{ jsonObj.footer.lt1}}
        </div>
        <div class="lt2">
          <div>
            <img src="@/assets/icon/positions.png" alt="">
            <span>{{ jsonObj.footer.lt2_1 }}</span>
          </div>
          <div>
            <img src="@/assets/icon/phones.png" alt="">
            <span>{{ jsonObj.footer.lt2_2 }}</span>
          </div>
          <div>
            <img src="@/assets/icon/email.png" alt="">
            <span>{{ jsonObj.footer.lt2_3 }}</span>
          </div>
        </div>
       
      </div>
      <div class="cont">
        <div class="cont1">
          {{ jsonObj.footer.cont1 }}
        </div>
        <div class="cont2Box">
            <div>
                <div class="cont2" @click.stop="jumpRouterchild('story','user')">
                    {{ jsonObj.footer.cont2_1 }}
                </div>
                <div class="cont2" @click="jumpRouterchild('cultivate','serve')">
                    {{ jsonObj.footer.cont2_2 }}
                </div>
                <div class="cont2" @click.stop="jumpRouterchild('schoolmajor','school')">
                    {{ jsonObj.footer.cont2_3 }}
                </div>
            </div>
            <div>
              <div class="cont2"  @click.stop="jumpRouterchild('specialty','school')">
                    {{ jsonObj.footer.cont2_4 }}
                </div>
                <div class="cont2"  @click="jumpRouterchild('scholarship','scholarship')">
                    {{ jsonObj.footer.cont2_5 }}
                </div>
                <div class="cont2">
                    <!-- {{ jsonObj.footer.cont2_6 }} -->
                </div>
            </div>
        </div>
      </div>
      <div class="right">
        <div class="r1">
          {{ jsonObj.footer.r2 }}
        </div>
        <div class="rt3">
          <div>
            <img src="@/assets/icon/facebookfff.png" alt="" @click.stop="jumpUrl('https://web.facebook.com/eiceducationvn')">
          </div>
          <div>
            <img src="@/assets/icon/zalo.png" alt=""  @click.stop="jumpUrl('https://zalo.me/0906225916')">
          </div>
          <div>
            <img src="@/assets/icon/email.png" alt="" @click.stop="jumpRouterchild('contact','user')">
          </div>
        </div>
        <div class="fenge"></div>
        <div class="tips">{{ jsonObj.footer.tips }}</div>
      </div>
      </div>
      
    </div>
    
  </template>
  
  <script setup name="footer">
 import {jsonObj,imgUrl} from '@/utlis/constJSON.js'
import {goRouter} from '@/router/index.js'

 const jumpUrl = (url)=>{
    window.open(url, '_blank');
  }
  const jumpRouterchild = (str,fatStr,query={})=>{
  jsonObj.headerNav = fatStr
  goRouter(str,query)
}
  </script>
  <style lang="scss" scoped>
  .footer{
    width: 86rem;
    margin: 0 auto;
    background-color: #004163;
    color: #fff;
    padding: 5rem 5rem 1.35rem 5rem;
    .footerbox{
    display: flex;
    justify-content: space-between;
    .left{
      width: 25.44rem;
      text-align: left;
      font-size: 1.2rem;
      img{
        width: 13.99rem;
        padding-bottom: 15px;
      }
      .lt1{
        width: 280px;
      }
      .lt2{
        padding-top:2.7rem;
        div{
          display: flex;
          justify-content: left;
          img{
            width: 1.6rem;
            height: 1.6rem;
            padding-right: 0.5rem;
          }
          span{
            color: #FFFFFF;
          }
        }
      }
      
    }
    .cont{
      width: 20.25rem;
      padding-top: 3.5rem;
      .cont1{
        font-weight: 500;
        color: #FFFFFF;
        padding-bottom: 2.5rem;
        font-size: 1.5rem;
      }
      .cont2Box{
        display: flex;
        justify-content: space-between;
        .cont2{
            width: 9.8rem;
            font-size: 1rem;
            color: #FFFFFF;
            padding-bottom: 1rem;
            cursor: pointer;
        }
      }
      
    }
    .right{
      width: 30rem;
      padding-top: 3.5rem;
      .r1{
        font-size: 1.2rem;
      }
      .rt3{
        padding: 2.5rem 0 1.9rem;
        display: flex;
        justify-content: left;
        div{
          padding-right: 1.5rem;
          img{
            width: 2.1rem;
            height: 2.1rem;
            cursor: pointer;
          }
        }
      }
        .fenge{
            width: 27.8rem;
            border-top: 0.03rem solid #F5AA3B;
        }
        .tips{
            padding: 1rem 0 0 ;
            font-weight: 300;
            font-size: 0.7rem !important;
            color: #FFFFFF;
        }
    }
    
    }
    
  }
  
  </style>