<template>
  <div v-if="state.loading">
    <div class="appPC" v-if="!state.isPC">
      <Header />
      <div class="view">
        <router-view />
      </div>
      <Footer v-if="!state.isFooter"></Footer>
      <Footer2 v-if="state.isFooter"></Footer2>
    </div>
    <div v-if="state.isPC" class="appmobile">
      <MobileHeader />
      <div class="viewmobile">
        <div class="view">
          <router-view />
        </div>
      </div>
        <MobileFooter/>
    </div>
  </div>
 
</template>
<script setup name="app">
import {  computed, ref,onMounted,  nextTick, watch, reactive,onUnmounted } from 'vue';
import {UA} from '@/utlis/util.js'
import Header from '@/components/header'
import Footer from '@/components/footer'
import Footer2 from '@/components/footer2'
import MobileFooter from '@/components/mobileFooter'
import MobileHeader from '@/components/mobileHeader'


const state = reactive({
  loading:false,
  isPC:false,
  isFooter:false,
})
const handleResize = () => {
      console.log(window.innerWidth,window.matchMedia('(orientation: landscape)').matches)
      state.isPC=!(window.matchMedia('(orientation: landscape)').matches)
};
const changeisFooter = ()=>{
  // 判断路由展示底部
  let path = window.location.pathname;
  state.isFooter = path.indexOf('contact') > -1
  state.isPC = document.body.clientWidth < 600
  state.loading = true
}
onMounted(()=>{
  changeisFooter()
  window.addEventListener('resize', handleResize);
})
onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    })
</script>


<style lang="scss">
html,body{
  margin: 0 auto;
  font-size: 20px;
  padding: 0;
  @font-face {
    font-family: 'Alibaba Sans Viet';
    // src: url('./Fonts/AlibabaSansViet-Bd.ttf') format('truetype');
    // src: url('./Fonts/AlibabaSansViet-Md.ttf') format('truetype');
    src: url('./Fonts/AlibabaSansViet-Rg.ttf') format('truetype');
    // src: url('./Fonts/AlibabaSansViet-Bd.otf') format('truetype');
    // src: url('./Fonts/AlibabaSansViet-Md.otf') format('truetype');
    // src: url('./Fonts/AlibabaSansViet-Rg.otf') format('truetype');
  }
  font-family: Alibaba Sans Viet;
}
@media (max-width: 800px) {
  /* 针对小于 768px 宽度设备的样式 */
  html,body {
    font-size:4px;
  }
  .view{
    padding-top: 12rem;
  }
}

@media (min-width: 800px) and (max-width: 1024px) {
  /* 针对 390px 到 1024px 宽度设备的样式 */
  html,body {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  /* 针对大于 1024px 宽度设备的样式 */
  html,body {
    font-size: 20px;
  }
}
.appPC{
  // margin: 0 auto;
  width: 100%;
}

.view{
  width: 96rem;
  margin: 0 auto;
}
.appmobile{
  margin: 0 auto;
}
.viewmobile{
  overflow-x:auto ;
}
.viewmobile::-webkit-scrollbar {
  display: none;
}
.btn{
  cursor: pointer;
}
p{
  padding: 0;
  margin: 0;
}
a{
  text-decoration: none;
  color: black;
  margin: 0;
  padding: 0;
}
a:hover{
  text-decoration-line: underline;
  margin: 0;
  padding: 0;
}
</style>
