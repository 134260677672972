<template>
  <div class="footer" id="footer">
    <div class="footerbox">
      <div class="left">
      <img src="@/assets/icon/icon.png" alt="">
      <div class="lt1">
        {{ jsonObj.footer.lt1}}
      </div>
      <div class="lt2">
        <div>
          <img src="@/assets/icon/positions.png" alt="">
          <span>{{ jsonObj.footer.lt2_1 }}</span>
        </div>
        <div>
          <img src="@/assets/icon/phones.png" alt="">
          <span>{{ jsonObj.footer.lt2_2 }}</span>
        </div>
        <div>
          <img src="@/assets/icon/email.png" alt="">
          <span>{{ jsonObj.footer.lt2_3 }}</span>
        </div>
      </div>
      <div class="lt3">
        <div>
          <img src="@/assets/icon/facebookfff.png" alt="" @click.stop="jumpUrl('https://www.facebook.com/eiceducationvn')">
        </div>
        <div>
          <img src="@/assets/icon/zalo.png" alt="" @click.stop="jumpUrl('https://zalo.me/0906225916')">
        </div>
        <div>
          <img src="@/assets/icon/email.png" alt="" @click.stop="jumpRouterchild('contact','user')">
        </div>
      </div>
    </div>
    <div class="cont">
      <div class="cont1" >
        {{ jsonObj.footer.cont1 }}
      </div>
      <div class="cont2" @click.stop="jumpRouterchild('story','user')">
        {{ jsonObj.footer.cont2_1  }}
      </div>
      <div class="cont2" @click="jumpRouterchild('cultivate','serve')">
        {{ jsonObj.footer.cont2_2  }}
      </div>
      <div class="cont2" @click.stop="jumpRouterchild('schoolmajor','school')">
        {{ jsonObj.footer.cont2_3  }}
      </div>
      <div class="cont2" @click.stop="jumpRouterchild('specialty','school')">
        {{ jsonObj.footer.cont2_4  }}
      </div>
      <div class="cont2" @click="jumpRouterchild('scholarship','scholarship')">
        {{ jsonObj.footer.cont2_5  }}
      </div>
      <div class="cont2">
        <!-- {{ jsonObj.footer.cont2_6  }} -->
      </div>
    </div>
    <div class="right">
      <div class="ri">
        {{ jsonObj.footer.r1 }}
      </div>
      <el-form ref="formRef" :model="jsonObj.footerfrom" :rules="jsonObj.rules">
        <div class="fromdiv">
          <el-form-item prop="name">
            <el-input
              v-model="jsonObj.footerfrom.name"
              style="width: 30rem;height: 2.1rem;background: rgba(255,255,255,0.05);border-radius: 0rem 0rem 0rem 0rem;"
              :placeholder="jsonObj.footer.nameplaceholder"
            />
          </el-form-item>
        </div>
        <div class="fromdiv">
          <el-form-item prop="age">
            <el-input
            v-model="jsonObj.footerfrom.age"
            style="width: 30rem;height: 2.1rem;background: rgba(255,255,255,0.05);border-radius: 0rem 0rem 0rem 0rem;"
            :placeholder="jsonObj.footer.birthdayplaceholder"
          />
          </el-form-item>
          
        </div>
        <div class="fromdiv phoneandEmil">
          <el-form-item prop="mobile">
            <el-input
            v-model="jsonObj.footerfrom.mobile"
            style="width: 14.5rem;height: 2.1rem;background: rgba(255,255,255,0.05);border-radius: 0rem 0rem 0rem 0rem;"
            :placeholder="jsonObj.footer.phoneplaceholder"
          />
          </el-form-item>
          
          <el-form-item prop="email">
            <el-input
            v-model="jsonObj.footerfrom.email"
            style="width: 14.5rem;height: 2.1rem;background: rgba(255,255,255,0.05);border-radius: 0rem 0rem 0rem 0rem;"
            :placeholder="jsonObj.footer.Emailplaceholder"
          />
          </el-form-item>

          
        </div>
        <div class="fromdiv learnandsend">
          <el-form-item prop="academicDegree">
            <el-select
            v-model="jsonObj.footerfrom.academicDegree"
            :placeholder="jsonObj.footer.learnplaceholder"
            style="width: 23rem;height: 2.1rem;background: rgba(255,255,255,0.05);border-radius: 0rem 0rem 0rem 0rem;"
            size="large"
            clearable 
          >
            <el-option :label="jsonObj.footer.learnOption1" :value="jsonObj.footer.learnOption1"></el-option>
            <el-option :label="jsonObj.footer.learnOption2" :value="jsonObj.footer.learnOption2"></el-option>
            <el-option :label="jsonObj.footer.learnOption3" :value="jsonObj.footer.learnOption3"></el-option>
            <el-option :label="jsonObj.footer.learnOption4" :value="jsonObj.footer.learnOption4"></el-option>
          </el-select>
          </el-form-item>

          <el-form-item>
            <!-- <el-button class="click"  :class="state.sendOK ? 'noClick' : ''" @click="send">{{ jsonObj.footer.send }}</el-button> -->
            <el-button class="click" @click="send">{{ jsonObj.footer.send }}</el-button>

          </el-form-item>
        </div>
      </el-form>
      
    </div>
    </div>
    <div class="fenge"></div>
    <div class="tips">{{jsonObj.footer.tips }}</div>
  </div>
  
</template>

<script setup name="footer">
  import { reactive,ref } from 'vue';
import {jsonObj,imgUrl,submitForm} from '@/utlis/constJSON.js'
import {goRouter} from '@/router/index.js'
import { ElMessage, ElMessageBox } from 'element-plus'
const formRef = ref()
  const state = reactive({
    sendOK:false,
  })
  const send = ()=>{
    console.log(jsonObj.footerfrom)
    formRef.value?.validate(async (valid) => {
        if (!valid) return false;
        await submitForm()
    })
  }
  const jumpUrl = (url)=>{
    window.open(url, '_blank');
  }
const jumpRouterchild = (str,fatStr,query={})=>{
  jsonObj.headerNav = fatStr
  goRouter(str,query)
}
</script>
<style lang="scss" scoped>
.footer{
  width: 86rem;
  margin: 0 auto;
  background-color: #004163;
  color: #fff;
  padding: 5rem 7.3rem 1.35rem 5rem;
  .footerbox{
  display: flex;
  justify-content: left;
  .left{
    width: 33.44rem;
    text-align: left;
    font-size: 1.2rem;
    img{
      width: 13.99rem;
      padding-bottom: 15px;
    }
    .lt1{
      width: 280px;
    }
    .lt2{
      padding-top:2.7rem;
      div{
        padding-top: 0.9rem;
        display: flex;
        justify-content: left;
        img{
          width: 1.6rem;
          height: 1.6rem;
          padding-right: 0.5rem;
        }
        span{
          color: #FFFFFF;
        }
      }
    }
    .lt3{
      padding-top: 1.4rem;
      display: flex;
      justify-content: left;
      div{
        padding-right: 1.5rem;
        img{
          width: 2.1rem;
          height: 2.1rem;
          cursor: pointer;
        }
      }
    }
  }
  .cont{
    width: 20.25rem;
    padding-top: 0.25rem;
    .cont1{
      font-weight: 500;
      color: #FFFFFF;
      padding-bottom: 2.5rem;
      font-size: 1.5rem;
    }
    .cont2{
      font-size: 1rem;
      color: #FFFFFF;
      padding-bottom: 1rem;
      cursor: pointer;
    }
  }
  .right{
    width: 30rem;
    .ri{
      font-size: 1.2rem;
      padding-bottom: 1.5rem;
    }
    .fromdiv{
      padding-bottom: 1rem;
    }
    .phoneandEmil{
      display: flex;
      justify-content: space-between;
    }
    .learnandsend{
      display: flex;
      justify-content: space-between;
      .click{
        width: 6rem;
        height: 2.1rem;
        background: #F5AA3B;
        border: 1px solid #AAAAAA;
      }
    }
  }
  
  }
  .fenge{
    width: 48.73rem;
    border-top: 0.03rem solid #F5AA3B;
  }
  .tips{
    padding: 1rem 0 0 ;
      font-weight: 300;
      font-size: 0.7rem !important;
      color: #FFFFFF;
    }
}

</style>